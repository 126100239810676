div#userwayAccessibilityIcon {
    top: 70px;
    right: 30px;

    @include desktop-1230{
        top: 58px;
    }

    @include screen-767{
        top: 55px;
        right: 15px;
    }

    @include phone-580{
        top: auto;
        right: auto;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
    }
}