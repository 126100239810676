/*********************************
4. Home
*********************************/

.home
{
	width: 100%;
	height: 100vh;

	@include screen-767{
		height: 60vh;
	}

	@include phone-580{
		height: 70vh;
	}
}


/*********************************
4.1 Home Slider
*********************************/

.home_slider_container
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	background: #ffffff;
}
.home_slider
{
	width: 100%;
	height: 100%;
}
.home_slider_item
{
	width: 100%;
	height: 100%;
}

.home_slider_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	/*opacity:0.5;*/
}

.home_slider_background_second
{	
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	/*opacity:0.5;*/
}

.home_slider_background_third
{	
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	/*opacity:0.5;*/
}

.home_slider_content
{
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 11;
}
.home_slider_content span
{
	margin-bottom: 0px;
	display: block;
}
.home_slider_content span:first-child
{
	font-size: 90px;
	font-weight: 500;
	color: #FFFFFF;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);

	@include desktop-1230{
		font-size: 80px;
	}

	@include screen-767{
		font-size: 60px;
	}
	@include phone-580{
		font-size: 36px;
	}
}
.home_slider_content span:nth-child(2)
{
    font-size: 100px;
    font-family: 'Beyond', sans-serif;
	color: #FFFFFF;
	margin-top: -10px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
	line-height: 100%;
	
	@include desktop-1230{
		font-size: 70px;
	}

	@include screen-767{
		font-size: 70px;
	}
	@include phone-580{
		margin-top: 0;
		font-size: 36px;
	}
}
.home_slider_button
{
	margin-top: 70px;
}
.flipInX
{
	animation-delay: 600ms;
}

/*********************************
4.2 Home Slider Nav
*********************************/

.home_slider_nav
{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 11;
	cursor: pointer;
}
.home_slider_prev
{
	left: 50px;

	@include phone-580{
		left: 15px;
	}
}
.home_slider_next
{
	right: 50px;
	@include phone-580{
		right: 15px;
	}
}
.home_slider_prev:hover .nav_path,
.home_slider_prev:hover .nav_arrow
{
	fill: url(#home_grad_prev);
}
.home_slider_next:hover .nav_arrow,
.home_slider_next:hover .nav_path
{
	fill: url(#home_grad_next);
}

/*********************************
4.3 Home Slider Dots
*********************************/

.home_slider_dots
{
	position: absolute;
	right: 50px;
	bottom: 45px;
	z-index: 10;
}

.home_slider_custom_dot
{
	display: inline-block;
	position: relative;
	height: 35px;
	line-height: 35px;
	color: #FFFFFF;
	cursor: pointer;
	font-size: 13px;
	font-weight: 600;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.home_slider_custom_dot:hover
{
	color: #fa9e1c;
}
.home_slider_custom_dot:hover.active div
{
	border-color: #fa9e1c;
}
.home_slider_custom_dot.active
{
	padding-left: 13px;
	padding-right: 9px;
	margin-right: 3px;
}
.home_slider_custom_dot div
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: solid 2px transparent;
	border-radius: 18px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.home_slider_custom_dot.active div
{
	border: solid 2px #FFFFFF;
}
