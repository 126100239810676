.home-header {
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
  background: url(../assets/images/home-header.jpg) no-repeat;
  background-position: center;
  background-size: cover;
}
.home-header .heading {
  text-align: center;
}
.home-header .heading h1 {
  color: #fff;
  font-size: 90px;
  font-weight: 500;
}
.home-header .heading h2 {
  font-family: "Beyond", sans-serif;
  color: #fff;
  font-size: 100px;
  line-height: 100%;
}

.welcome-block {
  background: #22807c;
}
.welcome-block .about-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.welcome-block .about-content p {
  font-size: 1.55rem;
  color: #fff;
  line-height: 40px;
  text-align: justify;
  font-weight: 400;
}
@media screen and (max-width: 1600px) {
  .welcome-block .about-content p {
    font-size: 1.35rem;
    line-height: 35px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1229px) {
  .welcome-block .about-content p {
    font-size: 1.25rem;
    line-height: 30px;
  }
}
.welcome-block .about-bg {
  background: url(../assets/images/welcome.jpg) no-repeat;
  height: 100%;
  min-height: 600px;
  background-size: cover;
  background-position: center;
  position: relative;
}
@media screen and (max-width: 1600px) {
  .welcome-block .about-bg {
    min-height: 490px;
  }
}
.welcome-block .about-bg .about-border {
  background: #fff;
  width: 6px;
  height: 65%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.welcome-block .welcome-title {
  font-size: 60px;
  color: #fff;
  position: relative;
  display: inline-block;
  font-weight: 500;
}
.welcome-block .welcome-title::before {
  bottom: -10px;
  left: 0;
  height: 3px;
  width: 120%;
  position: absolute;
  content: "";
  background: #fff;
}
.welcome-block .welcome-title.no-psudo::before {
  display: none;
}
.welcome-block .welcome-subtitle {
  font-size: 2.1875rem;
  color: #fff;
  position: relative;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .welcome-block .welcome-subtitle {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1229px) {
  .welcome-block .welcome-subtitle {
    font-size: 2rem;
  }
}
.welcome-block .welcome-subtitle::before {
  bottom: -10px;
  left: 0;
  height: 3px;
  width: 102%;
  position: absolute;
  content: "";
  background: #fff;
}
@media screen and (min-width: 1024px) and (max-width: 1229px) {
  .welcome-block .welcome-subtitle::before {
    width: 102%;
  }
}
@media screen and (max-width: 767px) {
  .welcome-block .welcome-subtitle::before {
    width: 100%;
    height: 2px;
  }
}

.request-form .welcome-title {
  font-size: 60px;
  color: #fff;
  position: relative;
  display: inline-block;
  font-weight: 500;
}
@media screen and (max-width: 801px) {
  .request-form .welcome-title {
    font-size: 35px;
  }
}
.request-form .welcome-title::before {
  bottom: -10px;
  left: 0;
  height: 3px;
  width: 120%;
  position: absolute;
  content: "";
  background: #fff;
}

.accommodation-block .accommodation-title {
  color: #434141;
  font-size: 60px;
  position: relative;
  display: inline-block;
  font-weight: 500;
}
@media screen and (max-width: 801px) {
  .accommodation-block .accommodation-title {
    font-size: 35px;
  }
}
.accommodation-block .accommodation-title::before {
  bottom: -10px;
  left: 0;
  height: 3px;
  width: 120%;
  position: absolute;
  content: "";
  background: #22807c;
}
.accommodation-block .single-accommodation .room-bg {
  height: 350px;
  width: 100%;
  position: relative;
}
@media screen and (max-width: 1600px) {
  .accommodation-block .single-accommodation .room-bg {
    height: 250px;
  }
}
.accommodation-block .single-accommodation .room-bg.studio-2-bg {
  background: url(../assets/images/rooms/studio-2.jpg) no-repeat;
  background-position: 0;
  background-size: cover;
}
.accommodation-block .single-accommodation .room-bg.budget-studio-2-bg {
  background: url(../assets/images/rooms/Budget-studio-2.jpg) no-repeat;
  background-position: 0;
  background-size: cover;
}
.accommodation-block .single-accommodation .room-bg.studio-2adults-2child-bg {
  background: url(../assets/images/rooms/Double-studio.jpg) no-repeat;
  background-position: 0;
  background-size: cover;
}
.accommodation-block .single-accommodation .room-bg.budget-studio-2adults-1c-bg {
  background: url(../assets/images/home-accomodation/budget-studio-2adults-1child.jpg) no-repeat;
  background-position: 0;
  background-size: cover;
}
.accommodation-block .single-accommodation .room-bg.studio-1st-floor-bg {
  background: url(../assets/images/rooms/Double-studio.jpg) no-repeat;
  background-position: 0;
  background-size: cover;
}
.accommodation-block .single-accommodation .room-bg.studio-3-bg {
  background: url(../assets/images/rooms/Studio-3.jpg) no-repeat;
  background-position: 0;
  background-size: cover;
}
.accommodation-block .single-accommodation .room-bg .price-tag {
  background: #bf9f6d;
  display: inline-block;
  color: #fff;
  font-size: 27px;
  font-weight: 500;
  padding: 5px 20px;
  line-height: 100%;
  margin-bottom: 0;
  position: absolute;
  right: -20px;
  bottom: 20px;
}
@media screen and (max-width: 1600px) {
  .accommodation-block .single-accommodation .room-bg .price-tag {
    font-size: 22px;
  }
}
@media screen and (max-width: 801px) {
  .accommodation-block .single-accommodation .room-bg .price-tag {
    padding-right: 10px;
    right: -10px;
  }
}
.accommodation-block .single-accommodation .room-bg .price-tag span {
  display: block;
  font-size: 70%;
  text-align: right;
  margin-top: -8px;
  font-weight: 400;
}
@media screen and (max-width: 1600px) {
  .accommodation-block .single-accommodation .room-bg .price-tag span {
    margin-top: -5px;
  }
}
.accommodation-block .single-accommodation .room-bg .price-tag.view-btn {
  bottom: 90px;
  padding: 0;
  background: transparent;
}
.accommodation-block .single-accommodation .room-bg .price-tag.view-btn a {
  background: #22807c;
  padding: 15px 20px;
  display: inline-block;
  border: 1px solid transparent;
  transition: all 0.4s ease;
}
.accommodation-block .single-accommodation .room-bg .price-tag.view-btn a:hover {
  background: #ff5e00;
  border-color: #ff5e00;
  color: #fff;
}
.accommodation-block .single-accommodation .room-details {
  background: #f0f0ef;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  padding: 10px 15px;
}
.accommodation-block .single-accommodation .room-details h3 {
  color: #434141;
  font-size: 36px;
  font-weight: 500;
}
@media screen and (max-width: 1600px) {
  .accommodation-block .single-accommodation .room-details h3 {
    font-size: 28px;
  }
}
@media screen and (max-width: 801px) {
  .accommodation-block .single-accommodation .room-details h3 {
    font-size: 22px;
  }
}
.accommodation-block .single-accommodation .room-details h3 span {
  display: block;
  font-size: 60%;
  font-weight: 400;
}
.accommodation-block .single-accommodation .room-details h3 a {
  transition: all 0.5s;
}
.accommodation-block .single-accommodation .room-details h3 a:hover {
  color: #ff5e00;
}
.accommodation-block .single-accommodation .room-details a.book-now {
  color: #434141;
  font-size: 30px;
  line-height: 100%;
  text-align: center;
  font-weight: 600;
  padding-left: 20px;
  border-left: 1px solid #22807c;
  transition: all 0.5s;
}
.accommodation-block .single-accommodation .room-details a.book-now:hover {
  color: #ff5e00;
}
@media screen and (max-width: 1600px) {
  .accommodation-block .single-accommodation .room-details a.book-now {
    font-size: 24px;
  }
}
.accommodation-block .gallery-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto 60px;
}
.accommodation-block .gallery-btn a.roomgallery {
  font-size: 34px;
  color: #fff;
  font-weight: 500;
  border: 2px solid transparent;
  display: inline-block;
  padding: 5px 30px;
  transition: all 0.4s ease-in;
  background: #ff5e00;
}
@media screen and (max-width: 1600px) {
  .accommodation-block .gallery-btn a.roomgallery {
    font-size: 30px;
  }
}
.accommodation-block .gallery-btn a.roomgallery:hover {
  background-color: #da3c00;
  color: #fff;
}
.accommodation-block .amenities-wrap {
  background: #22807c;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 60px;
  padding: 0 30px 60px;
}
.accommodation-block .amenities-wrap .amenitites-title-sm {
  background-color: #1b5c66;
  color: #fff;
  font-size: 35px;
  display: inline-block;
  padding: 5px 40px;
  font-weight: 500;
  margin-top: -25px;
  width: 70%;
  text-align: center;
}
@media screen and (max-width: 1600px) {
  .accommodation-block .amenities-wrap .amenitites-title-sm {
    width: 80%;
  }
}
@media screen and (max-width: 801px) {
  .accommodation-block .amenities-wrap .amenitites-title-sm {
    width: 90%;
  }
}
.accommodation-block .amenities-wrap ul {
  text-align: center;
  padding-top: 40px;
}
.accommodation-block .amenities-wrap ul li {
  color: #fff;
  font-size: 24px;
  margin-bottom: 5px;
}
@media screen and (max-width: 1600px) {
  .accommodation-block .amenities-wrap ul li {
    font-size: 20px;
    margin-bottom: 0;
  }
}

#gallery-section .gallery-title {
  color: #434141;
  font-size: 60px;
  position: relative;
  display: inline-block;
  font-weight: 500;
}
#gallery-section .gallery-title::before {
  bottom: -10px;
  left: 0;
  height: 3px;
  width: 120%;
  position: absolute;
  content: "";
  background: #22807c;
}
#gallery-section .gallery-title h2 {
  font-size: 6rem;
  font-weight: 500;
  transform: translateX(0%);
}
#gallery-section .gallery-layout {
  height: 100vh;
  width: 100%;
  position: relative;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(3, 1fr);
}
@media screen and (max-width: 801px) {
  #gallery-section .gallery-layout {
    grid-template-columns: auto;
    height: 100%;
  }
}
#gallery-section .g-bg {
  transition: all 0.5s ease-in-out;
  height: 100%;
  width: 100%;
  position: relative;
}
@media screen and (max-width: 801px) {
  #gallery-section .g-bg {
    height: 50vh;
  }
}
#gallery-section .g-bg:hover {
  transform: scale(1.05);
}
#gallery-section .gallery-col-1 {
  display: grid;
  grid-gap: 15px;
  grid-template-rows: 1fr 1fr;
}
#gallery-section .gallery-col-1 .col-1-top {
  position: relative;
  overflow: hidden;
}
#gallery-section .gallery-col-1 .col-1-top .g-bg {
  background: url(../assets/images/home-gallery/Hotel-Demo-08.jpg) no-repeat;
  background-position: center;
  background-size: cover;
}
#gallery-section .gallery-col-1 .col-1-bottom {
  position: relative;
  overflow: hidden;
}
#gallery-section .gallery-col-1 .col-1-bottom .g-bg {
  background: url(../assets/images/home-gallery/Hotel-Demo-02.jpg) no-repeat;
  background-position: center;
  background-size: cover;
}
#gallery-section .gallery-col-2 {
  display: grid;
  grid-gap: 15px;
  grid-template-rows: repeat(3, 1fr);
}
#gallery-section .gallery-col-2 .col-2-top {
  position: relative;
  overflow: hidden;
}
#gallery-section .gallery-col-2 .col-2-top .g-bg {
  background: url(../assets/images/home-gallery/Hotel-Demo-03.jpg) no-repeat;
  background-position: center;
  background-size: cover;
}
#gallery-section .gallery-col-2 .col-2-center {
  position: relative;
  overflow: hidden;
}
#gallery-section .gallery-col-2 .col-2-center .g-bg {
  background: url(../assets/images/home-gallery/Hotel-Demo-04.jpg) no-repeat;
  background-position: center;
  background-size: cover;
}
#gallery-section .gallery-col-2 .col-2-bottom {
  position: relative;
  overflow: hidden;
}
#gallery-section .gallery-col-2 .col-2-bottom .g-bg {
  background: url(../assets/images/home-gallery/Hotel-Demo-05.jpg) no-repeat;
  background-position: center;
  background-size: cover;
}
#gallery-section .gallery-col-3 {
  display: grid;
  grid-gap: 15px;
  grid-template-rows: 3fr 2fr;
}
@media screen and (max-width: 801px) {
  #gallery-section .gallery-col-3 {
    grid-template-rows: auto;
  }
}
#gallery-section .gallery-col-3 .col-3-top {
  position: relative;
  overflow: hidden;
}
#gallery-section .gallery-col-3 .col-3-top .g-bg {
  background: url(../assets/images/home-gallery/Hotel-Demo-06.jpg) no-repeat;
  background-position: center;
  background-size: cover;
}
#gallery-section .gallery-col-3 .col-3-bottom {
  position: relative;
  overflow: hidden;
}
#gallery-section .gallery-col-3 .col-3-bottom .g-bg {
  background: url(../assets/images/home-gallery/Hotel-Demo-07.jpg) no-repeat;
  background-position: center;
  background-size: cover;
}
#gallery-section .g-bg:before {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  content: "";
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

#gallery-section .g-bg:hover:before {
  opacity: 1;
}

a.big {
  position: absolute;
}

/* *ESPA Banner style */
a.espa-banner {
  position: absolute;
  left: 50px;
  bottom: 30px;
  z-index: 12;
}

@media screen and (max-width: 768px) {
  a.espa-banner {
    right: auto;
    bottom: 30px;
    left: 15px;
  }
}
@media screen and (max-width: 768px) and (max-width: 767px) {
  a.espa-banner img {
    max-width: 80px;
  }
}
/* Form Modal Style */
div#Booking_btn {
  background: rgba(227, 224, 221, 0.8);
}

.request-form label {
  display: inline-block;
  font-size: 1.3em;
  line-height: 1em;
  letter-spacing: 0.04em;
  margin-bottom: 5px;
  /* padding: 0 0 0 15px; */
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  text-align: left;
  margin-top: 20px;
  font-weight: 500;
}
@media screen and (max-width: 1600px) {
  .request-form label {
    font-size: 1rem;
  }
}

form#RequestForm {
  background: #084e4e;
  padding: 20px 45px;
  border: 1px solid #084e4e;
  border-radius: 10px;
}
@media screen and (max-width: 801px) {
  form#RequestForm {
    padding: 20px 15px;
  }
}

.request-form input,
.request-form select {
  /* border-color: $white; */
  border: none;
  border-bottom: 1px solid #fff;
  width: 100%;
  background: transparent;
  padding: 15px 0;
  margin-bottom: 15px;
  font-size: 1.2rem;
  -webkit-appearance: none;
  outline: none;
  color: #fff;
}
@media screen and (max-width: 1600px) {
  .request-form input,
  .request-form select {
    font-size: 1rem;
  }
}

input.custom-input {
  padding: 5px 10px;
}

.request-form textarea {
  border: 1px solid #fff;
  background: transparent;
  width: 100%;
  max-height: 120px;
  resize: none;
  padding: 10px;
  margin-top: 15px;
  outline: none;
  color: #fff;
  font-size: 1.3em;
  margin-bottom: 10px;
}

.request-form {
  padding-top: 60px;
  padding-bottom: 60px;
  background: #22807c;
}

.modal-dialog {
  max-width: 1200px;
  width: 100%;
}

label.MM_label span {
  font-size: 10px;
}

button.close {
  position: absolute;
  font-size: 57px;
  color: #22807c;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 20px;
  right: 15px;
  top: 0px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: 9999999 !important;
}

button.close span {
  margin-top: -10px;
}

h1.modal_title {
  text-align: center;
  margin-bottom: 50px;
  color: #22807c;
}

.request-form input[type=submit] {
  width: auto;
  border: 1px solid transparent;
  display: block;
  padding: 15px 40px;
  text-align: center;
  margin: 15px auto;
  cursor: pointer;
  transition: all 0.4s;
  margin-top: 0px;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  background: #ff5e00;
  font-size: 28px;
  border-radius: 5px;
}
@media screen and (max-width: 801px) {
  .request-form input[type=submit] {
    padding: 15px 20px;
  }
}

input[type=submit]:hover {
  background: #da3c00;
  transition: all 0.4s;
  color: #22807c;
  border: 1px solid transparent;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  color: #fff;
}

.checkin span,
.checkout span {
  display: block;
  position: relative;
}

.input-wrap {
  display: flex !important;
  align-items: center;
}
.input-wrap .input-icon {
  margin-left: -30px;
  pointer-events: none;
}
.input-wrap .input-icon img {
  max-width: 30px;
  width: 100%;
}

.checkin i,
.checkout i {
  position: absolute;
  right: 15px;
  top: 16px;
  font-size: 30px;
  pointer-events: none;
}

.person {
  display: inline;
  position: relative;
}

.person i {
  position: absolute;
  font-size: 30px;
  right: -16px;
  top: 40px;
  pointer-events: none;
  display: none;
}

.request-form i {
  color: #fff;
  cursor: pointer;
}

input#checkin, input#checkout {
  cursor: pointer;
}

.left-photo {
  /* background: url(../images/formbg.jpg) center center no-repeat; */
  background-size: cover;
  /* height: 45vh; */
}

.img-box img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  transition: opacity 2s ease-in-out;
  -webkit-transition: opacity 2s ease-in-out;
  -moz-transition: opacity 2s ease-in-out;
  -ms-transition: opacity 2s ease-in-out;
  -o-transition: opacity 2s ease-in-out;
  animation-name: formFadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: inherit;
  animation-duration: 7s;
  animation-direction: alternate;
}

@keyframes formFadeInOut {
  0% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  92% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.img-box img:nth-of-type(1) {
  animation-delay: 10s;
}

.img-box img:nth-of-type(2) {
  animation-delay: 6s;
}

.img-box img:nth-of-type(3) {
  animation-delay: 3s;
}

/* Custom select dropdown  style */
.custom-select {
  position: relative;
}
@media screen and (max-width: 801px) {
  .custom-select {
    max-width: 100% !important;
  }
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: transparent;
}

/*style the arrow inside the select element:*/
/* .select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
} */
/*point the arrow upwards when the select box is open (active):*/
/* .select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
} */
/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: #fff;
  padding: 15px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
  border: none;
  border-bottom: 1px solid #fff;
  font-size: 1.25rem;
  font-family: "Gilroy", "Open Sans";
}

.custom-select.villa-dropdown .select-items div,
.custom-select.villa-dropdown .select-selected {
  font-size: 1.2rem;
}

@media screen and (max-width: 767px) {
  .custom-select.villa-dropdown .select-items div,
  .custom-select.villa-dropdown .select-selected {
    font-size: 1.1rem;
  }
}
/*style items (options):*/
.select-items {
  position: absolute;
  /* background-color: pink; */
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99999;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  color: #fff;
  background-color: #ff5e00;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}

.col-lg-12.custom-column {
  z-index: -1;
}

.select-items div {
  color: #444;
  background: #ccc;
  border-color: #ddd;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}

div#ui-datepicker-div {
  z-index: 9999999999 !important;
}

.ui-widget-header {
  border: 1px solid #22807c;
  color: #ffffff;
  background: #22807c;
}

a.ui-state-default.ui-state-active {
  background: #ff5e00;
  color: #fff !important;
  border-color: transparent !important;
  text-align: center;
}

.ui-datepicker-next.ui-corner-all::before {
  content: "";
  background: url(../assets/icons/right-arrow.svg) no-repeat;
  background-size: contain;
  background-position: center;
  width: 20px;
  height: 20px;
  font-size: 1.25em;
  right: 10px;
  left: auto;
  position: absolute;
  top: 9px;
}

.ui-datepicker-prev.ui-corner-all:before {
  content: "";
  left: 9px;
  background: url(../assets/icons/left-arrow.svg) no-repeat;
  background-size: contain;
  background-position: center;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 9px;
  font-size: 1.25em;
}

.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center;
  font-family: "Gilroy", "Open Sans";
  font-weight: 500;
  font-size: 1.2em;
}

.ui-datepicker table {
  width: 100%;
  /* font-size: .9em; */
  border-collapse: collapse;
  margin: 0 0 0.6em;
  font-family: "Gilroy", "Open Sans";
  font-weight: 400 !important;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
  border: 1px solid #f2f5f7;
  background: #22807c;
  font-weight: 400;
  color: #ffffff !important;
  font-size: 1.2em;
  text-align: center;
}

.ui-datepicker td span,
.ui-datepicker td a {
  display: block;
  padding: 0.3em;
  text-align: right;
  text-decoration: none;
}

.ui-widget.ui-widget-content {
  border: 1px solid #a7a7a7;
}

.row.custom-row:hover .custom-column {
  z-index: 1;
}

/* form SLider */
.imgBox img {
  max-width: 100%;
  border-radius: 10%;
  -webkit-border-radius: 10%;
  -moz-border-radius: 10%;
  -ms-border-radius: 10%;
  -o-border-radius: 10%;
}

#message {
  color: #ff5e00;
  font-size: 23px;
  text-align: center;
}

.request-form #message {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  margin: 0;
  bottom: 240px;
  width: 100%;
}

#message.success-box {
  position: relative;
  top: 0;
}

#success_page {
  padding: 50px 15px 40px 15px;
  border: 1px solid #084e4e;
  border-radius: 5px;
  background: #084e4e;
}

.success-thank {
  font-family: "Gilroy", "Open Sans" !important;
  font-weight: 400;
  font-size: 1.3em;
  line-height: 1.4em;
  color: #fff;
  margin-bottom: 10px;
}

.success {
  color: #fff;
}

span.optional {
  text-transform: none;
  font-style: italic;
  font-size: 0.75em !important;
  letter-spacing: normal;
}

.success-spam {
  font-size: 1em;
  padding-top: 20px;
  color: #ff5e00;
}

textarea::-webkit-input-placeholder {
  color: #fff;
}

textarea:-moz-placeholder { /* Firefox 18- */
  color: #fff;
}

textarea::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}

textarea:-ms-input-placeholder {
  color: #fff;
}

textarea::placeholder {
  color: #fff;
}

@media screen and (max-width: 767px) {
  #message {
    font-size: 16px;
    line-height: 1;
    bottom: 235px !important;
    padding: 0 30px;
  }
  #message.success-box {
    padding: 0 !important;
    margin-top: 30px !important;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  section.request-form .col-md-5, section.request-form .col-md-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  section.welcome-block .col-md-5, section.welcome-block .col-md-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .welcome-block .about-content {
    margin-top: 30px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .form_slider {
    margin-bottom: 30px;
  }
}

.google-recaptcha .g-recaptcha {
  margin-top: 30px;
}

/* Gilroy */
@font-face {
  font-family: "Gilroy";
  src: url("https://cdn.web-dynamic.gr/common/fonts/Gilroy/Gilroy-Light.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 300;
}
@font-face {
  font-family: "Gilroy";
  src: url("https://cdn.web-dynamic.gr/common/fonts/Gilroy/Gilroy-Regular.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: "Gilroy";
  src: url("https://cdn.web-dynamic.gr/common/fonts/Gilroy/Gilroy-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("https://cdn.web-dynamic.gr/common/fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}
@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 300;
  src: local("AvenirLTStd-Light"), url("https://cdn.web-dynamic.gr/common/fonts/Avenir/AvenirLTStd-Light.otf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  src: local("AvenirLTStd-Book"), url("https://cdn.web-dynamic.gr/common/fonts/Avenir/AvenirLTStd-Book.otf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  src: local("AvenirLTStd-Roman"), url("https://cdn.web-dynamic.gr/common/fonts/Avenir/AvenirLTStd-Roman.otf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("https://cdn.web-dynamic.gr/common/fonts/Open_Sans/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("https://cdn.web-dynamic.gr/common/fonts/Open_Sans/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("https://cdn.web-dynamic.gr/common/fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Beyond";
  src: url("../fonts/beyond_the_mountains.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Beyond";
  src: url("../fonts/beyond_the_mountains.otf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
/* Back-to-button fonts */
@font-face {
  font-family: "Flaticon";
  src: url("https://cdn.web-dynamic.gr/common/fonts/Flaticon/Flaticon.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.flaticon-up-arrow:before {
  content: "\f100";
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a:hover {
  color: inherit;
}

body {
  font-size: 16px;
  font-family: "Gilroy", sans-serif;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

p, li {
  font-weight: 300;
}

div#main-wrapper {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

h1, h2, h3, h3, h5, h6 {
  font-family: "Gilroy", sans-serif;
  margin: 0;
  font-weight: 400;
}

a, a:hover {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

svg {
  height: 100% !important;
}

div#main-wrapper.margin-x3 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.fancybox-caption-wrap {
  display: none;
}

footer {
  background-color: #333;
  padding: 80px 0 40px;
}
@media screen and (max-width: 801px) {
  footer .padding-x2 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
footer ul.f-menu {
  text-align: center;
  overflow: hidden;
}
@media screen and (max-width: 801px) {
  footer ul.f-menu {
    margin-top: 30px;
  }
}
footer ul.f-menu li {
  display: inline-block;
  margin-left: -4px;
}
@media screen and (max-width: 801px) {
  footer ul.f-menu li {
    display: block;
    margin-bottom: 5px;
  }
}
footer ul.f-menu li a {
  padding: 5px 20px;
  font-size: 1.3rem;
  font-weight: 500;
  transition: all 0.4s ease-in;
  color: #fff;
  transition: all 0.3s ease-in;
}
@media screen and (max-width: 1600px) {
  footer ul.f-menu li a {
    font-size: 1.1rem;
    padding: 5px 10px;
  }
}
@media screen and (max-width: 801px) {
  footer ul.f-menu li a {
    font-size: 1.25rem;
  }
}
footer ul.f-menu li a:hover {
  color: #ff5e00;
}
footer ul.f-menu li a.opacity {
  opacity: 0.1;
}
@media screen and (max-width: 1600px) {
  footer .footer-map iframe {
    height: 225px;
  }
}
footer .footer-social ul {
  text-align: center;
  overflow: hidden;
}
@media screen and (max-width: 801px) {
  footer .footer-social ul {
    margin-bottom: 30px;
  }
}
footer .footer-social ul li {
  display: inline-block;
  margin-left: -4px;
  overflow: hidden;
}
footer .footer-social ul li a {
  padding: 5px 20px;
  transition: all 0.4s ease-in;
}
@media screen and (max-width: 767px) {
  footer .footer-social ul li a {
    padding: 5px 15px;
  }
}
footer .footer-social ul li a.opacity {
  opacity: 0.1;
}
footer .footer-social ul li a svg {
  fill: #fff;
  transition: all 0.5s;
}
footer .footer-social ul li a:hover svg {
  fill: #ff5e00;
}
footer .footer-logo {
  overflow: hidden;
  position: relative;
}
footer .footer-logo div {
  overflow: hidden;
  margin: 0 auto;
  display: block;
  transform-origin: left;
  position: relative;
}
footer .footer-logo a {
  overflow: hidden;
  position: relative;
}
footer .footer-logo img {
  max-width: 230px;
  margin: 0 auto;
  display: block;
}
@media screen and (max-width: 1600px) {
  footer .footer-logo img {
    max-width: 180px;
  }
}
footer .footer-address {
  display: block;
  margin: 30px auto;
  overflow: hidden;
}
footer .footer-address a {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  display: block;
  padding: 10px;
  font-size: 1.25rem;
  font-weight: 500;
  transition: all 0.4s ease-in;
  text-align: center;
  color: #fff;
}
footer .footer-address a:hover {
  color: #ff5e00;
}
footer .footer-address a span {
  margin-right: 10px;
}
footer .footer-address a svg {
  width: 20px;
  fill: #fff;
}
footer svg {
  width: 30px;
  height: auto;
}

#main-nav {
  background-color: rgba(42, 125, 125, 0.9019607843);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
  padding: 0px;
}
@media screen and (max-width: 801px) {
  #main-nav {
    padding: 0px;
  }
}
@media screen and (max-width: 767px) {
  #main-nav {
    padding: 0px 0 5px 0;
  }
}
#main-nav.fix-nav {
  box-shadow: 0 2px 10px #ccc;
}
#main-nav nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#main-nav nav .logo img {
  max-width: 200px;
  padding: 15px 0;
}
@media screen and (max-width: 1600px) {
  #main-nav nav .logo img {
    max-width: 180px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1229px) {
  #main-nav nav .logo img {
    max-width: 140px;
  }
}
@media screen and (max-width: 801px) {
  #main-nav nav .logo img {
    max-width: 120px;
  }
}
@media screen and (max-width: 767px) {
  #main-nav nav .logo img {
    position: relative;
    top: 0px;
    padding: 10px 0;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1399px) {
  #main-nav nav ul.nav-list {
    margin-right: 60px;
  }
}
#main-nav nav ul li {
  display: inline-block;
}
#main-nav nav ul li a {
  color: #fff;
  font-size: 26px;
  display: inline-block;
  padding: 5px 25px;
  font-weight: 400;
  transition: all 0.4s ease-in-out;
}
@media screen and (max-width: 1600px) {
  #main-nav nav ul li a {
    font-size: 22px;
    padding: 5px 20px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1229px) {
  #main-nav nav ul li a {
    font-size: 20px;
    padding: 5px 9px;
  }
}
#main-nav nav ul li a.req-btn {
  border: 1px solid #de6f21;
  background-color: #ff5e00;
}
#main-nav nav ul li a.req-btn:hover {
  background-color: #da3c00;
  color: #fff;
  border-color: transparent;
}
#main-nav nav ul li a:hover {
  color: #ffda87;
}
#main-nav #top-bar {
  background: #084e4e;
  padding: 10px 0;
  display: flex;
  align-items: center;
}
#main-nav #top-bar svg {
  width: 20px;
  fill: #fff;
  height: auto;
  margin-right: 8px;
}
@media screen and (max-width: 767px) {
  #main-nav #top-bar svg {
    width: 24px;
    margin-right: 5px;
  }
}
#main-nav #top-bar .address-top-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}
#main-nav #top-bar .address-top-bar a {
  color: #fff;
  font-size: 1.125rem;
  margin-right: 30px;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
}
@media screen and (min-width: 1024px) and (max-width: 1229px) {
  #main-nav #top-bar .address-top-bar a {
    font-size: 0.9rem;
    margin-right: 10px;
  }
}
@media screen and (max-width: 767px) {
  #main-nav #top-bar .address-top-bar a {
    margin-right: 15px;
  }
}
#main-nav #top-bar .address-top-bar a svg {
  fill: #fff;
}
#main-nav #top-bar .address-top-bar a:hover {
  color: #ff5e00;
}
#main-nav #top-bar .address-top-bar a:hover svg {
  fill: #ff5e00;
}
#main-nav #top-bar .top-bar-social svg {
  width: 22px;
  height: auto;
  fill: #fff;
  transition: all 0.4s ease;
  margin: 0;
}
#main-nav #top-bar .top-bar-social ul li a {
  padding: 5px 10px 5px 25px;
  font-size: 100%;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  #main-nav #top-bar .top-bar-social ul li a {
    padding-left: 13px;
  }
}
#main-nav #top-bar .top-bar-social ul li a:last-child {
  padding-right: 0;
}
#main-nav #top-bar .top-bar-social ul li a:hover svg {
  fill: #ff5e00;
}
@media screen and (max-width: 801px) {
  #main-nav #top-bar span.m-hide {
    display: none;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  #main-nav #top-bar span.m-hide.visible-tablet-port {
    display: block;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  #main-nav #top-bar span.m-hide.ipad-none {
    display: none !important;
  }
}

nav#mobile-nav ul {
  height: 100%;
  background: #084e4e;
  position: fixed;
  width: 100%;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  transition: all 0.5s ease-in;
  transform: translate(100%);
}
nav#mobile-nav ul.show {
  transform: translateX(0%);
}
nav#mobile-nav ul li {
  font-size: 1.6rem;
  margin-bottom: 10px;
}

.m-req-btn a {
  color: #fff;
  font-size: 1.3rem;
  display: inline-block;
  border: 1px solid #de6f21;
  padding: 3px 10px;
  position: absolute;
  right: 90px;
  top: 62px;
  z-index: 99;
  background: #ff5e00;
}
@media screen and (max-width: 801px) {
  .m-req-btn a {
    right: 140px;
  }
}
@media screen and (max-width: 767px) {
  .m-req-btn a {
    right: 70px;
  }
}

/*********************************
4. Home
*********************************/
.home {
  width: 100%;
  height: 100vh;
}
@media screen and (max-width: 801px) {
  .home {
    height: 60vh;
  }
}
@media screen and (max-width: 767px) {
  .home {
    height: 70vh;
  }
}

/*********************************
4.1 Home Slider
*********************************/
.home_slider_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: #ffffff;
}

.home_slider {
  width: 100%;
  height: 100%;
}

.home_slider_item {
  width: 100%;
  height: 100%;
}

.home_slider_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  /*opacity:0.5;*/
}

.home_slider_background_second {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  /*opacity:0.5;*/
}

.home_slider_background_third {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  /*opacity:0.5;*/
}

.home_slider_content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 11;
}

.home_slider_content span {
  margin-bottom: 0px;
  display: block;
}

.home_slider_content span:first-child {
  font-size: 90px;
  font-weight: 500;
  color: #FFFFFF;
  text-shadow: 1px 1px 2px rgb(0, 0, 0);
}
@media screen and (min-width: 1024px) and (max-width: 1229px) {
  .home_slider_content span:first-child {
    font-size: 80px;
  }
}
@media screen and (max-width: 801px) {
  .home_slider_content span:first-child {
    font-size: 60px;
  }
}
@media screen and (max-width: 767px) {
  .home_slider_content span:first-child {
    font-size: 36px;
  }
}

.home_slider_content span:nth-child(2) {
  font-size: 100px;
  font-family: "Beyond", sans-serif;
  color: #FFFFFF;
  margin-top: -10px;
  text-shadow: 1px 1px 2px rgb(0, 0, 0);
  line-height: 100%;
}
@media screen and (min-width: 1024px) and (max-width: 1229px) {
  .home_slider_content span:nth-child(2) {
    font-size: 70px;
  }
}
@media screen and (max-width: 801px) {
  .home_slider_content span:nth-child(2) {
    font-size: 70px;
  }
}
@media screen and (max-width: 767px) {
  .home_slider_content span:nth-child(2) {
    margin-top: 0;
    font-size: 36px;
  }
}

.home_slider_button {
  margin-top: 70px;
}

.flipInX {
  animation-delay: 600ms;
}

/*********************************
4.2 Home Slider Nav
*********************************/
.home_slider_nav {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 11;
  cursor: pointer;
}

.home_slider_prev {
  left: 50px;
}
@media screen and (max-width: 767px) {
  .home_slider_prev {
    left: 15px;
  }
}

.home_slider_next {
  right: 50px;
}
@media screen and (max-width: 767px) {
  .home_slider_next {
    right: 15px;
  }
}

.home_slider_prev:hover .nav_path,
.home_slider_prev:hover .nav_arrow {
  fill: url(#home_grad_prev);
}

.home_slider_next:hover .nav_arrow,
.home_slider_next:hover .nav_path {
  fill: url(#home_grad_next);
}

/*********************************
4.3 Home Slider Dots
*********************************/
.home_slider_dots {
  position: absolute;
  right: 50px;
  bottom: 45px;
  z-index: 10;
}

.home_slider_custom_dot {
  display: inline-block;
  position: relative;
  height: 35px;
  line-height: 35px;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.home_slider_custom_dot:hover {
  color: #fa9e1c;
}

.home_slider_custom_dot:hover.active div {
  border-color: #fa9e1c;
}

.home_slider_custom_dot.active {
  padding-left: 13px;
  padding-right: 9px;
  margin-right: 3px;
}

.home_slider_custom_dot div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 2px transparent;
  border-radius: 18px;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.home_slider_custom_dot.active div {
  border: solid 2px #FFFFFF;
}

.inner-header {
  height: 80vh;
  display: grid;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 801px) {
  .inner-header {
    height: 50vh;
  }
}
@media screen and (max-width: 767px) {
  .inner-header {
    height: 55vh;
  }
}
.inner-header#request {
  background: url(../assets/images/request-header.jpg) no-repeat;
  background-position: center;
  background-size: cover;
}
.inner-header#contact {
  background: url(../assets/images/contact-header.jpg) no-repeat;
  background-position: center bottom;
  background-size: cover;
}
.inner-header#gallery-header {
  background: url(../assets/images/gallery-header.jpg) no-repeat;
  background-position: top;
  background-size: cover;
}
.inner-header#services {
  background: url(../assets/images/services-header.jpg) no-repeat;
  background-position: center top;
  background-size: cover;
}
.inner-header#accommodation {
  background: url(../assets/images/accommodation-header.jpg) no-repeat;
  background-position: 0;
  background-size: cover;
}
.inner-header .inner-hero-title {
  color: #fff;
  font-size: 120px;
  font-weight: 500;
  text-shadow: 0 2px 10px rgba(34, 34, 34, 0.5);
}
@media screen and (max-width: 801px) {
  .inner-header .inner-hero-title {
    font-size: 40px;
  }
}
@media screen and (max-width: 767px) {
  .inner-header .inner-hero-title {
    margin-top: 80px;
  }
}

.welcome-title.req-title {
  color: #434141;
  font-size: 60px;
  position: relative;
  display: inline-block;
  font-weight: 500;
}
@media screen and (max-width: 801px) {
  .welcome-title.req-title {
    font-size: 40px;
  }
}
.welcome-title.req-title::before {
  bottom: -10px;
  left: 0;
  height: 3px;
  width: 120%;
  position: absolute;
  content: "";
  background: #22807c;
}

.facilities-block .facilities-title {
  font-size: 60px;
  position: relative;
  display: inline-block;
  font-weight: 500;
  color: #434141;
}
@media screen and (max-width: 801px) {
  .facilities-block .facilities-title {
    font-size: 45px;
  }
}
.facilities-block .facilities-title::before {
  bottom: -10px;
  left: 0;
  height: 3px;
  width: 120%;
  position: absolute;
  content: "";
  background: #22807c;
}
@media screen and (max-width: 767px) {
  .facilities-block .facilities-title::before {
    width: 100%;
  }
}
.facilities-block .facilitites-wrapper {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  grid-gap: 60px;
  padding: 30px 0;
  height: 650px;
}
@media screen and (max-width: 1600px) {
  .facilities-block .facilitites-wrapper {
    height: 580px;
    grid-gap: 30px;
  }
}
@media screen and (max-width: 801px) {
  .facilities-block .facilitites-wrapper {
    min-height: 55px;
    height: 100%;
    grid-template-columns: auto;
  }
  .facilities-block .facilitites-wrapper .left-col {
    order: 2;
  }
  .facilities-block .facilitites-wrapper .right-col {
    order: 3;
  }
}
@media screen and (max-width: 801px) {
  .facilities-block .facilitites-wrapper .center-col {
    order: 1;
  }
}
.facilities-block .facilitites-wrapper .center-col .facilities-bg {
  background: url(../assets/images/facilities.jpg) no-repeat;
  background-position: 0;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}
@media screen and (max-width: 801px) {
  .facilities-block .facilitites-wrapper .center-col .facilities-bg {
    min-height: 350px;
  }
}
.facilities-block .facilitites-wrapper .center-col .facilities-bg .border-f {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border: 3px solid #fff;
  height: 90%;
  width: 90%;
}
.facilities-block .facilitites-wrapper .f-list {
  background-color: #22807c;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 60px;
}
@media screen and (max-width: 1600px) {
  .facilities-block .facilitites-wrapper .f-list {
    padding: 40px 30px;
  }
}
.facilities-block .facilitites-wrapper .f-list h3 {
  color: #fff;
  font-size: 2.3rem;
  text-align: center;
  margin-bottom: 30px;
}
.facilities-block .facilitites-wrapper .f-list li, .facilities-block .facilitites-wrapper .f-list p {
  color: #fff;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 33px;
}
@media screen and (max-width: 1600px) {
  .facilities-block .facilitites-wrapper .f-list li, .facilities-block .facilitites-wrapper .f-list p {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 1600px) {
  .facilities-block .facilitites-wrapper .f-list ul {
    padding-left: 25px;
  }
}
.facilities-block .facilitites-wrapper .f-list ul li {
  margin-bottom: 3px;
  position: relative;
}
.facilities-block .facilitites-wrapper .f-list ul li::before {
  position: absolute;
  left: -25px;
  top: 12px;
  width: 8px;
  height: 8px;
  background-color: #fff;
  content: "";
  border-radius: 50px;
}

/* back to top */
.progress-wrap {
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
@media screen and (max-width: 801px) {
  .progress-wrap {
    right: 15px;
    bottom: 20px;
  }
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.progress-wrap::after {
  position: absolute;
  content: "\f100";
  font-family: "Flaticon";
  text-align: center;
  line-height: 46px;
  font-size: 20px;
  color: #c57f13;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap:hover::after {
  opacity: 1;
}

.progress-wrap::before {
  position: absolute;
  content: "\f100";
  font-family: "Flaticon";
  text-align: center;
  line-height: 46px;
  font-size: 20px;
  opacity: 0;
  /* background-image: linear-gradient(298deg, #da2c4d, #f8ab37); */
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 2;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap:hover::before {
  opacity: 1;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #c57f13;
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.contact-block {
  background: #22807c;
}

#contact-wrap {
  max-width: 1280px;
  padding: 0 15px;
  margin: 0 auto;
  margin: 0px auto;
}
#contact-wrap .contact-section-title {
  color: #fff;
  font-size: 60px;
  position: relative;
  display: inline-block;
  font-weight: 500;
}
#contact-wrap .contact-section-title::before {
  bottom: -10px;
  left: 0;
  height: 3px;
  width: 120%;
  position: absolute;
  content: "";
  background: #fff;
}
@media screen and (max-width: 801px) {
  #contact-wrap {
    margin-top: 0;
  }
}
#contact-wrap .contact-form-wrap {
  margin-bottom: 80px;
  box-shadow: 0 2px 10px #000;
}
#contact-wrap svg {
  height: auto;
  width: 100%;
  fill: #ccc;
  max-width: 30px;
}
@media screen and (max-width: 801px) {
  #contact-wrap svg {
    max-width: 20px;
  }
}
#contact-wrap h3 {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #22807c;
  padding-bottom: 15px;
  position: relative;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) {
  #contact-wrap h3 {
    font-size: 1.95rem;
  }
}
#contact-wrap #success_message {
  color: #33a20f !important;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 44px;
  text-align: center;
}
#contact-wrap .contact-title {
  position: relative;
}
#contact-wrap .contact-title::before {
  width: 70px;
  height: 50px;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background: url(../assets/icons/contact/send.svg) no-repeat;
  background-position: 100%;
  background-size: cover;
  opacity: 0.5;
}
@media screen and (max-width: 801px) {
  #contact-wrap .contact-title::before {
    width: 55px;
  }
}
#contact-wrap .contact-form-wrap {
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: center;
  background-color: #222;
}
#contact-wrap .contact-form-wrap .form-wrapper {
  padding: 40px 30px;
  display: grid;
  align-items: center;
}
#contact-wrap .contact-form-wrap .form-wrapper .input-box-col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}
#contact-wrap .contact-form-wrap .form-wrapper .input-box-col-1 {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}
#contact-wrap .contact-form-wrap .form-wrapper input {
  width: 100%;
  padding: 5px 5px 2px 15px;
  border: 1px solid rgba(204, 204, 204, 0.2);
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  outline: none;
  background: transparent;
  margin-top: 15px;
  font-weight: 400;
}
#contact-wrap .contact-form-wrap .form-wrapper #btn_sent {
  background: #ff5e00;
  width: 100%;
  display: flex;
  align-items: flex-end;
  max-width: 300px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  font-size: 25px;
  transition: all 0.5s;
  border: 1px solid transparent;
  color: #fff;
  margin: 0 auto;
}
#contact-wrap .contact-form-wrap .form-wrapper #btn_sent:hover {
  background: #da3c00;
}
#contact-wrap .contact-form-wrap .form-wrapper label {
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 400;
  font-weight: 500;
  color: #fff;
}
#contact-wrap .contact-form-wrap .form-wrapper label.error {
  font-size: 1rem;
  left: 0;
  bottom: -29px;
  top: auto;
  color: red;
}
#contact-wrap .contact-form-wrap .form-wrapper textarea#con_message {
  width: 100%;
  height: 120px;
  padding: 15px;
  margin-bottom: 25px;
  border: 1px solid rgba(204, 204, 204, 0.2);
  border-radius: 5px;
  font-size: 1.25rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 15px;
  outline: none;
  background: transparent;
}
@media screen and (max-width: 767px) {
  #contact-wrap .contact-form-wrap .form-wrapper #success_message {
    font-size: 2rem;
  }
}
#contact-wrap .information-wrapper {
  background: #353b48;
  color: #fff;
  padding: 50px 60px;
}
#contact-wrap .information-wrapper .address-box {
  padding-left: 0px;
}
#contact-wrap .information-wrapper h2 {
  color: #fff;
  text-align: center;
}
@media screen and (max-width: 801px) {
  #contact-wrap .information-wrapper h2 {
    margin-bottom: 40px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  #contact-wrap .information-wrapper h2 {
    margin-bottom: 40px;
  }
}
#contact-wrap .information-wrapper .informtion-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 40vh;
}
#contact-wrap .information-wrapper .address-box .address {
  margin-bottom: 35px;
  display: flex;
  align-items: center;
}
#contact-wrap .information-wrapper .address-box .address a {
  font-size: 1.25rem;
  line-height: 30px;
  font-weight: normal;
  color: #fff;
  margin-left: 10px;
  font-size: 1.2rem;
}
@media screen and (max-width: 1600px) {
  #contact-wrap .information-wrapper .address-box .address a {
    font-size: 1.2rem;
  }
}
#contact-wrap .information-wrapper .address-box .address a:hover {
  color: #ff5e00;
}
#contact-wrap .information-wrapper .address-box .address a:hover svg {
  fill: #ff5e00;
}
#contact-wrap .information-wrapper .address-box .address svg {
  margin-right: 15px;
}
@media screen and (max-width: 1600px) {
  #contact-wrap .information-wrapper .address-box .address svg {
    margin-right: 8px;
  }
}
#contact-wrap .information-wrapper .contact-social ul {
  text-align: center;
}
#contact-wrap .information-wrapper .contact-social ul li {
  display: inline-block;
  margin: 0 15px;
}
#contact-wrap .information-wrapper .contact-social ul li svg {
  width: 30px;
  fill: #fff;
  transition: all 0.4s;
}
#contact-wrap .information-wrapper .contact-social ul li svg:hover {
  fill: #ff5e00;
}
#contact-wrap .contact-map-wrap iframe {
  width: 100%;
}

@media screen and (max-width: 1599px) {
  #contact-wrap {
    max-width: 1080px;
  }
  #contact-wrap .contact-form-wrap .form-wrapper label {
    font-size: 1.25rem;
  }
  #contact-wrap .information-wrapper {
    padding: 50px 30px;
  }
  #contact-wrap .information-wrapper .form-wrapper label {
    font-size: 1.25rem !important;
  }
  #contact-wrap .contact-form-wrap .form-wrapper .input-box-col-2, #contact-wrap .contact-form-wrap .form-wrapper .input-box-col-1 {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  #contact-wrap {
    max-width: 960px;
    width: 100%;
  }
}
@media screen and (max-width: 1023px) {
  #contact-wrap {
    max-width: 860px;
    width: 100%;
    background: transparent;
  }
}
@media screen and (max-width: 1023px) and (max-width: 801px) {
  #contact-wrap {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 1023px) and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  #contact-wrap {
    margin-top: 65px;
  }
}
@media screen and (max-width: 1023px) {
  #contact-wrap .information-wrapper .informtion-box {
    align-items: center;
  }
  #contact-wrap .contact-form-wrap {
    grid-template-columns: auto;
  }
  #contact-wrap .contact-form-wrap .form-wrapper {
    order: 2;
  }
  #contact-wrap .contact-form-wrap .information-wrapper {
    order: 1;
  }
  #contact-wrap .contact-form-wrap, #contact-wrap .contact-map-wrap {
    margin: 0 0px 30px;
  }
}
@media screen and (max-width: 767px) {
  #contact-wrap .contact-form-wrap .form-wrapper .input-box-col-2 {
    grid-template-columns: auto;
  }
  #contact-wrap .contact-form-wrap .form-wrapper {
    padding: 30px 15px;
  }
  .contact-form-wrap .form-wrapper label {
    font-size: 0.8rem;
    padding-bottom: 5px;
    margin-bottom: 3px;
  }
}
.g-recaptcha {
  width: 100%;
  margin-top: -20px;
  margin-bottom: 15px;
  display: block;
}
@media screen and (max-width: 767px) {
  .g-recaptcha {
    margin-top: -10px;
  }
}

.g-recaptcha > div {
  margin: 0px auto;
}

div#captcha_error {
  /* background: red; */
  display: inline-block;
  width: 100%;
  text-align: center;
  transition: all 0.5s;
  margin-top: -10px;
  margin-bottom: 10px;
  font-size: 1.25rem;
  color: red;
}

.input-box-col-2 > div {
  position: relative;
}

#contact-wrap .contact-form-wrap .form-wrapper .input-box-col-1.messageBox {
  position: relative;
}
#contact-wrap .contact-form-wrap .form-wrapper .input-box-col-1.messageBox label.error {
  top: auto;
  bottom: 0;
}

.country-title {
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 10px;
}

.country-title span {
  font-size: 1.8rem;
  font-weight: 600;
  padding: 15px 5px;
  display: block;
  letter-spacing: 0px;
  color: #e96f26;
}

footer {
  background-color: #333;
  padding: 80px 0 40px;
}
@media screen and (max-width: 801px) {
  footer .padding-x2 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
footer ul.f-menu {
  text-align: center;
  overflow: hidden;
}
@media screen and (max-width: 801px) {
  footer ul.f-menu {
    margin-top: 30px;
  }
}
footer ul.f-menu li {
  display: inline-block;
  margin-left: -4px;
}
@media screen and (max-width: 801px) {
  footer ul.f-menu li {
    display: block;
    margin-bottom: 5px;
  }
}
footer ul.f-menu li a {
  padding: 5px 20px;
  font-size: 1.3rem;
  font-weight: 500;
  transition: all 0.4s ease-in;
  color: #fff;
  transition: all 0.3s ease-in;
}
@media screen and (max-width: 1600px) {
  footer ul.f-menu li a {
    font-size: 1.1rem;
    padding: 5px 10px;
  }
}
@media screen and (max-width: 801px) {
  footer ul.f-menu li a {
    font-size: 1.25rem;
  }
}
footer ul.f-menu li a:hover {
  color: #ff5e00;
}
footer ul.f-menu li a.opacity {
  opacity: 0.1;
}
@media screen and (max-width: 1600px) {
  footer .footer-map iframe {
    height: 225px;
  }
}
footer .footer-social ul {
  text-align: center;
  overflow: hidden;
}
@media screen and (max-width: 801px) {
  footer .footer-social ul {
    margin-bottom: 30px;
  }
}
footer .footer-social ul li {
  display: inline-block;
  margin-left: -4px;
  overflow: hidden;
}
footer .footer-social ul li a {
  padding: 5px 20px;
  transition: all 0.4s ease-in;
}
@media screen and (max-width: 767px) {
  footer .footer-social ul li a {
    padding: 5px 15px;
  }
}
footer .footer-social ul li a.opacity {
  opacity: 0.1;
}
footer .footer-social ul li a svg {
  fill: #fff;
  transition: all 0.5s;
}
footer .footer-social ul li a:hover svg {
  fill: #ff5e00;
}
footer .footer-logo {
  overflow: hidden;
  position: relative;
}
footer .footer-logo div {
  overflow: hidden;
  margin: 0 auto;
  display: block;
  transform-origin: left;
  position: relative;
}
footer .footer-logo a {
  overflow: hidden;
  position: relative;
}
footer .footer-logo img {
  max-width: 230px;
  margin: 0 auto;
  display: block;
}
@media screen and (max-width: 1600px) {
  footer .footer-logo img {
    max-width: 180px;
  }
}
footer .footer-address {
  display: block;
  margin: 30px auto;
  overflow: hidden;
}
footer .footer-address a {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  display: block;
  padding: 10px;
  font-size: 1.25rem;
  font-weight: 500;
  transition: all 0.4s ease-in;
  text-align: center;
  color: #fff;
}
footer .footer-address a:hover {
  color: #ff5e00;
}
footer .footer-address a span {
  margin-right: 10px;
}
footer .footer-address a svg {
  width: 20px;
  fill: #fff;
}
footer svg {
  width: 30px;
  height: auto;
}

.hamburger-wrap {
  width: 60px;
  height: 60px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  z-index: 999;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
.hamburger-wrap .hamburger-bar {
  width: 40px;
  height: 4px;
  background: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.hamburger-wrap .hamburger-bar::before, .hamburger-wrap .hamburger-bar::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 4px;
  background: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.hamburger-wrap .hamburger-bar::before {
  transform: translateY(-12px);
  -webkit-transform: translateY(-12px);
  -moz-transform: translateY(-12px);
  -ms-transform: translateY(-12px);
  -o-transform: translateY(-12px);
}
.hamburger-wrap .hamburger-bar::after {
  transform: translateY(12px);
  -webkit-transform: translateY(12px);
  -moz-transform: translateY(12px);
  -ms-transform: translateY(12px);
  -o-transform: translateY(12px);
  width: 50%;
}

#hamburger {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  top: 52px;
  position: fixed;
  right: 10px;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  #hamburger {
    right: 70px;
  }
}

.hamburger-wrap.open .hamburger-bar {
  background: transparent;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
}
.hamburger-wrap.open .hamburger-bar::before {
  transform: rotate(45deg) translate(35px, -35px);
  -webkit-transform: rotate(45deg) translate(35px, -35px);
  -moz-transform: rotate(45deg) translate(35px, -35px);
  -ms-transform: rotate(45deg) translate(35px, -35px);
  -o-transform: rotate(45deg) translate(35px, -35px);
}
.hamburger-wrap.open .hamburger-bar::after {
  transform: rotate(-45deg) translate(35px, 35px);
  -webkit-transform: rotate(-45deg) translate(35px, 35px);
  -moz-transform: rotate(-45deg) translate(35px, 35px);
  -ms-transform: rotate(-45deg) translate(35px, 35px);
  -o-transform: rotate(-45deg) translate(35px, 35px);
  width: 40px;
}
.hamburger-wrap.open .hamburger-bar .show-ipad {
  display: none !important;
}
.hamburger-wrap.open .hamburger-bar a.mobile-req.show-ipad {
  display: none !important;
}

.mobile-nav {
  position: relative;
  background: #077ed1;
  height: 60px;
  z-index: 999;
}
.mobile-nav .mobile-logo {
  position: absolute;
  left: 10px;
  transform: translateY(-50%);
  top: 50%;
}
.mobile-nav .mobile-logo img {
  max-width: 160px;
}
.mobile-nav .mobile-excursion-btn {
  position: absolute;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
  right: 75px;
  background: #fc6c1e;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}
.mobile-nav .mobile-excursion-btn a {
  color: #fff;
  text-align: center;
  font-size: 0.9rem;
}
.mobile-nav .mobile-excursion-btn a span {
  display: block;
}

.top-mobile-nav {
  background: #444;
  z-index: 999;
  position: relative;
}
.top-mobile-nav .social-nav {
  padding: 5px 0;
}
.top-mobile-nav .social-nav ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.top-mobile-nav .social-nav ul li {
  margin: 0 10px;
}
.top-mobile-nav .social-nav ul img {
  max-width: 28px;
}

ul#expend-nav {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #444;
  transform: translateY(-100%);
  transition: all 0.5s ease-in-out;
  z-index: 99;
}
ul#expend-nav li a {
  color: #fff;
  font-size: 1.35rem;
  line-height: 50px;
}

ul#expend-nav.show {
  transform: translateY(0);
}

a.mobile-book-btn {
  background: #fc6c1e;
  display: block;
  width: 190px;
  text-align: center;
  border-radius: 4px;
  margin-top: 35px;
  font-size: 1.4rem !important;
  font-weight: 500;
  margin-bottom: -35px;
}

body.fixed-nav .mobile-nav {
  position: fixed;
  top: 0;
  z-index: 999;
  left: 0;
  right: 0;
  box-shadow: 0 1px 12px #777;
}

div#userwayAccessibilityIcon {
  top: 70px;
  right: 30px;
}
@media screen and (min-width: 1024px) and (max-width: 1229px) {
  div#userwayAccessibilityIcon {
    top: 58px;
  }
}
@media screen and (max-width: 801px) {
  div#userwayAccessibilityIcon {
    top: 55px;
    right: 15px;
  }
}
@media screen and (max-width: 767px) {
  div#userwayAccessibilityIcon {
    top: auto;
    right: auto;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}