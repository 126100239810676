#main-nav{
    background-color: #2a7d7de6;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;
    padding: 0px;

    @include screen-767{
        padding: 0px;
    }
    @include phone-580{
        padding: 0px 0 5px 0;
    }

    &.fix-nav{
        box-shadow: 0 2px 10px #ccc;
    }

    nav{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo{
            img{
                max-width: 200px;
                padding: 15px 0;

                @include desktop-1366{
                    max-width: 180px;
                }
                @include desktop-1230{
                    max-width: 140px;
                }
                @include screen-767{
                    max-width: 120px;
                }
                @include phone-580{
                    position: relative;
                    top: 0px;
                    padding: 10px 0;
                }
            }
        }

        ul{

            &.nav-list{
                @include desktop-mid{
                    margin-right: 60px;
                }
            }
            li{
                display: inline-block;
                a{
                    color: $white;
                    font-size: 26px;
                    display: inline-block;
                    padding: 5px 25px;
                    font-weight: 400;
                    transition: all 0.4s ease-in-out;

                    @include desktop-1366{
                        font-size: 22px;
                        padding: 5px 20px;
                    }

                    @include desktop-1230{
                        font-size: 20px;
                        padding: 5px 9px;
                    }

                    &.req-btn{
                        border: 1px solid #de6f21;
                        background-color: #ff5e00;
                    }
                    &.req-btn:hover{
                        background-color: #da3c00; 
                        color: $white;
                        border-color: transparent;
                    }
                    &:hover{
                       color: #ffda87;
                    }

                }
            }
        }
    }

    #top-bar{
        background: #084e4e;
        padding: 10px 0;
        display: flex;
        align-items: center;
        svg{
            width: 20px;
            fill: #fff;
            height: auto;
            margin-right: 8px;

            @include phone-580{
                width: 24px;
                margin-right: 5px;
            }
        }
        .address-top-bar{
            display: flex;
            align-items: center;
            justify-content: center;
            a{
                color: #fff;
                font-size: 1.125rem;
                margin-right: 30px;
                display: flex;
                align-items: center;
                transition: all 0.5s ease;

                @include desktop-1230{
                    font-size: 0.9rem;
                    margin-right: 10px;
                }
                @include phone-580{
                    margin-right: 15px;
                }
                svg{
                    fill: #fff;
                }
                &:hover{
                    color: $hover;

                    svg{
                        fill: $hover;
                    }
                }
            }
        }

        .top-bar-social{
            svg{
                width: 22px;
                height: auto;
                fill: #fff;
                transition: all 0.4s ease;
                margin: 0;
            }

            ul{
                li{
                    a{
                        padding: 5px 10px 5px 25px;
                        font-size: 100%;
                        display: inline-block;

                        @include phone-580{
                            padding-left: 13px;
                        }

                        &:last-child{
                            padding-right: 0;
                        }

                        &:hover{
                            svg{
                                fill: $hover;
                            }
                        }
                    }
                }
            }
        }

        span.m-hide{
            @include screen-767{
                display: none;
            }
            @include iPad-portrait{
                &.visible-tablet-port{
                    display: block;
                }
            }

            &.ipad-none{
                @include iPad-portrait{
                    display: none !important;
                }
            }
        }

    }


}

// Mobile nav
nav#mobile-nav ul {
    height: 100%;
    background: #084e4e;
    position: fixed;
    width: 100%;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    transition: all 0.5s ease-in;
    transform: translate(100%);
    &.show{
        transform: translateX(0%);
    }
    li {
        font-size: 1.6rem;
        margin-bottom: 10px;
    }
}
.m-req-btn a {
    color: #fff;
    font-size: 1.3rem;
    display: inline-block;
    border: 1px solid #de6f21;
    padding: 3px 10px;
    position: absolute;
    right: 90px;
    top: 62px;
    z-index: 99;
    background: #ff5e00;

    @include screen-767{
        right: 140px;
    }

    @include phone-580{
        right: 70px;
    }
}
