footer{
    background-color: #333;
    padding: 80px 0 40px;
    .padding-x2{
        @include screen-767{
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
    }
    ul.f-menu {
        text-align: center;
        overflow: hidden;

        @include screen-767{
            margin-top: 30px;
        }

        li{
            display: inline-block;
            margin-left: -4px;

            @include screen-767{
                display: block;
                margin-bottom: 5px;
            }

            a{
                padding: 5px 20px;
                font-size: 1.3rem;
                font-weight: 500;
                transition: all 0.4s ease-in;
                color: $white;
                transition: all 0.3s ease-in;

                @include desktop-1366{
                    font-size: 1.1rem;
                    padding: 5px 10px;
                }

                @include screen-767{
                    font-size: 1.25rem;
                }

                &:hover{
                    color: $hover;
                }

                &.opacity{
                    opacity: 0.1;
                }
            }
        }
    }
    .footer-map iframe {
        @include desktop-1366{
            height: 225px;
        }
    }
    .footer-social{
        ul{
            @include screen-767{
                margin-bottom: 30px;
            }
            text-align: center;
            overflow: hidden;
            li{
                display: inline-block;
                margin-left: -4px;
                overflow: hidden;
                //transform: translateY(-100px);

                a{
                    padding: 5px 20px;
                    transition: all 0.4s ease-in;

                    @include phone-580{
                        padding: 5px 15px;
                    }

                    &.opacity{
                        opacity: 0.1;
                    }
                    svg{
                        fill: #fff;
                        transition: all 0.5s;
                    }
                    &:hover svg{
                        fill: $hover;
                    }
                }
            }
        }
    }
    .footer-logo{
        overflow: hidden; 
        position: relative;

        div{
            overflow: hidden;
            margin: 0 auto;
            display: block;
            transform-origin: left;
            position: relative;
        }

        a{
            overflow: hidden;
            position: relative;
        }
    }
    .footer-logo img {
        max-width: 230px;
        margin: 0 auto;
        display: block;

        
    @include desktop-1366{
        max-width: 180px;
      }

    }

    .footer-address{
        display: block;
        margin: 30px auto;
        overflow: hidden;
        a{
            max-width: 500px;
            width: 100%;
            margin: 0 auto;
            display: block;
            padding: 10px;
            font-size: 1.25rem;
            font-weight: 500;
            transition: all .4s ease-in;
            text-align: center;
            color: $white;

            &:hover{
                color: $hover;
            }
            span{
                margin-right: 10px;
            }
            svg{
                width: 20px;
                fill: $white;
            }

        }

    }
    svg{
        width: 30px;
        height: auto;
    }
}