// Hamburger css
.hamburger-wrap{
  width: 60px;
  height: 60px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  z-index: 999;
  visibility: hidden;
  pointer-events: none;
  @include transition;

  .hamburger-bar{
      width: 40px;
      height: 4px;
      background: #fff;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;

      &::before, &::after{
          content: '';
          position: absolute;
          width: 40px;
          height: 4px;
          background: #fff;
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          transition: all 0.5s ease-in-out;
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          -ms-transition: all 0.5s ease-in-out;
          -o-transition: all 0.5s ease-in-out;
      }

      &::before{
          transform: translateY(-12px);
          -webkit-transform: translateY(-12px);
          -moz-transform: translateY(-12px);
          -ms-transform: translateY(-12px);
          -o-transform: translateY(-12px);
}
      &::after{
          transform: translateY(12px);
          -webkit-transform: translateY(12px);
          -moz-transform: translateY(12px);
          -ms-transform: translateY(12px);
          -o-transform: translateY(12px);
          width: 50%;
}
  }
}
#hamburger{
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  top: 52px;
  position: fixed;
  right: 10px;

  @include iPad-portrait{
    right: 70px;
  }
}
.hamburger-wrap.open .hamburger-bar{
  background: transparent;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);

  &::before{
      transform: rotate(45deg) translate(35px, -35px);
      -webkit-transform: rotate(45deg) translate(35px, -35px);
      -moz-transform: rotate(45deg) translate(35px, -35px);
      -ms-transform: rotate(45deg) translate(35px, -35px);
      -o-transform: rotate(45deg) translate(35px, -35px);
  }
  &::after{
      transform: rotate(-45deg) translate(35px, 35px);
      -webkit-transform: rotate(-45deg) translate(35px, 35px);
      -moz-transform: rotate(-45deg) translate(35px, 35px);
      -ms-transform: rotate(-45deg) translate(35px, 35px);
      -o-transform: rotate(-45deg) translate(35px, 35px);
      width: 40px;
  }
  
.show-ipad{display: none !important;}
a.mobile-req.show-ipad {
  display: none !important;
}
}

// Mobile nav style
.mobile-nav {
  position: relative;
  background: #077ed1;
  height: 60px;
  z-index: 999;

  .mobile-logo {
    position: absolute;
    left: 10px;
    transform: translateY(-50%);
    top: 50%;

    img{
      max-width: 160px;
    }
  }
  .mobile-excursion-btn {
    position: absolute;
    left: auto;
    top: 50%;
    transform: translateY(-50%);
    right: 75px;
    background: #fc6c1e;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

    a{
      color: #fff;
      text-align: center;
      font-size: 0.9rem;
      span{
        display: block;
      }
    }
  }
  // top nav


}
.top-mobile-nav{
  background: #444;
  z-index: 999;
  position: relative;

  .social-nav {
  padding: 5px 0;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;

    li{
      margin: 0 10px;
    }

      img {
        max-width: 28px;
    }
  }
}
  
}

ul#expend-nav {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #444;
  transform: translateY(-100%);
  transition: all 0.5s ease-in-out;
  z-index: 99;
  li{
    a{
      color: #fff;
      font-size: 1.35rem;
      line-height: 50px;
    }
  }
}
ul#expend-nav.show{
  transform: translateY(0);
}
a.mobile-book-btn {
  background: #fc6c1e;
  display: block;
  width: 190px;
  text-align: center;
  border-radius: 4px;
  margin-top: 35px;
  font-size: 1.4rem!important;
  font-weight: 500;
  margin-bottom: -35px;
}
body.fixed-nav .mobile-nav {
  position: fixed;
  top: 0;
  z-index: 999;
  left: 0;
  right: 0;
  box-shadow: 0 1px 12px #777;
}