.home-header{
    height: 100vh;
    display: grid;
    align-items: center;
    justify-content: center;
    background: url(../assets/images/home-header.jpg) no-repeat;
    background-position: center;
    background-size: cover;

    .heading{
        text-align: center;
        h1{
            color: $white;
            font-size: 90px;
            font-weight: 500;
        }
        h2{
            font-family: 'Beyond', sans-serif;
            color: $white;
            font-size: 100px;
            line-height: 100%;
        }
    }
}

// Welcome block
.welcome-block{
    background: $primary-color;

    .about-content{
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        height: 100%;

        p{
            font-size: 1.55rem;
            color: $white;
            line-height: 40px;
            text-align: justify;
            font-weight: 400;

            @include desktop-1366{
                font-size: 1.35rem;
                line-height: 35px;
            }     

             @include desktop-1230{
                font-size: 1.25rem;
                line-height: 30px;
            }
        }
    }
    .about-bg{
        background: url(../assets/images/welcome.jpg) no-repeat;
        height: 100%;
        min-height: 600px;
        background-size: cover;
        background-position: center;
        position: relative;

        @include desktop-1366{
            min-height: 490px;
        }

        .about-border {
            background: $white;
            width: 6px;
            height: 65%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .welcome-title{
        font-size: 60px;
        color: $white;
        position: relative;
        display: inline-block;
        font-weight: 500;

        &::before{
            bottom: -10px;
            left: 0;
            height: 3px;
            width: 120%;
            position: absolute;
            content: "";
            background: $white;
        }

        &.no-psudo{
            &::before{
                display: none;
            }
        }
    }

    .welcome-subtitle{
        font-size: 2.1875rem;
        color: $white;
        position: relative;
        display: inline-block;

        @include phone-580{
            font-size: 1.5rem;
        }

        @include desktop-1230{
            font-size: 2rem;
        }

        &::before{
            bottom: -10px;
            left: 0;
            height: 3px;
            width: 102%;
            position: absolute;
            content: "";
            background: $white;

            @include desktop-1230{
                width: 102%;
            }

            @include phone-580{
                width: 100%;
                height: 2px;
            }
        }
    }
}

.request-form .welcome-title{
    font-size: 60px;
    color: $white;
    position: relative;
    display: inline-block;
    font-weight: 500;

    @include screen-767{
        font-size: 35px;
    }

    &::before{
        bottom: -10px;
        left: 0;
        height: 3px;
        width: 120%;
        position: absolute;
        content: "";
        background: $white;
    }
}

// Accommodation Block
.accommodation-block{
    .accommodation-title{
        color: #434141;
        font-size: 60px;
        position: relative;
        display: inline-block;
        font-weight: 500;
        @include screen-767{
            font-size: 35px;
        }

        &::before{
            bottom: -10px;
            left: 0;
            height: 3px;
            width: 120%;
            position: absolute;
            content: "";
            background: $primary-color;
        }
    }
    .single-accommodation{
        .room-bg{
            height: 350px;
            width: 100%;
            position: relative;

            @include desktop-1366{
                height: 250px;
            }
            
            &.studio-2-bg{
                background: url(../assets/images/rooms/studio-2.jpg) no-repeat;
                background-position: 0;
                background-size: cover;
            }
            &.budget-studio-2-bg{
                background: url(../assets/images/rooms/Budget-studio-2.jpg) no-repeat;
                background-position: 0;
                background-size: cover;
            }
            &.studio-2adults-2child-bg{
                background: url(../assets/images/rooms/Double-studio.jpg) no-repeat;
                background-position: 0;
                background-size: cover;
            }
            &.budget-studio-2adults-1c-bg{
                background: url(../assets/images/home-accomodation/budget-studio-2adults-1child.jpg) no-repeat;
                background-position: 0;
                background-size: cover;
            }
            &.studio-1st-floor-bg{
                background: url(../assets/images/rooms/Double-studio.jpg) no-repeat;
                background-position: 0;
                background-size: cover;
            }
            &.studio-3-bg{
                background: url(../assets/images/rooms/Studio-3.jpg) no-repeat;
                background-position: 0;
                background-size: cover;
            }
            .price-tag{
                background: #bf9f6d;
                display: inline-block;
                color: $white;
                font-size: 27px;
                font-weight: 500;
                padding: 5px 20px;
                line-height: 100%;
                margin-bottom: 0;
                position: absolute;
                right: -20px;
                bottom: 20px;

                @include desktop-1366{
                    font-size: 22px;
                }

                @include screen-767{
                    padding-right: 10px;
                    right: -10px;                
                }

                span{
                    display: block;
                    font-size: 70%;
                    text-align: right;
                    margin-top: -8px;
                    font-weight: 400;

                    @include desktop-1366{
                        margin-top: -5px;
                    }
                }

                &.view-btn{
                    bottom: 90px;
                    padding: 0;
                    background: transparent;

                    a{
                        background: $primary-color;
                        padding: 15px 20px;
                        display: inline-block;
                        border: 1px solid transparent;
                        transition: all 0.4s ease;

                        &:hover{
                            background: $hover;
                            border-color: $hover;
                            color: $white;
                        }
                    }
                }
                
            }
        }

        .room-details{
            background: #f0f0ef;
            @include flexCenter;
            justify-content: space-between;
            padding: 10px 15px;

            h3{
                color: #434141;
                font-size: 36px;
                font-weight: 500;

                @include desktop-1366{
                    font-size: 28px;
                }

                @include screen-767{
                    font-size: 22px;
                }

                span{
                    display: block;
                    font-size: 60%;
                    font-weight: 400;
                }
                a{
                    transition: all 0.5s;

                    &:hover{
                        color: #ff5e00;
                    }
                }
            }
            a.book-now {
                color: #434141;
                font-size: 30px;
                line-height: 100%;
                text-align: center;
                font-weight: 600;
                padding-left: 20px;
                border-left: 1px solid $primary-color;
                transition: all 0.5s;

                &:hover{
                    color: #ff5e00;
                }

                @include desktop-1366{
                    font-size: 24px;
                }
            }
        }
        
    }
    // gallery btn
    .gallery-btn{
        @include flexCenter;
        margin: 40px auto 60px;

        a.roomgallery{
            font-size: 34px;
            color: #fff;
            font-weight: 500;
            border: 2px solid transparent;
            display: inline-block;
            padding: 5px 30px;
            transition: all 0.4s ease-in;
            background: #ff5e00;

            @include desktop-1366{
                font-size: 30px;
            }

            &:hover{
                background-color: #da3c00;
                color: $white;
            }
        }
    }
    // amenities
    .amenities-wrap{
        background: $primary-color;
        @include flexCenter;
        flex-direction: column;
        margin-top: 60px;
        padding: 0 30px 60px;

        .amenitites-title-sm{
            background-color: #1b5c66;
            color: $white;
            font-size: 35px;
            display: inline-block;
            padding: 5px 40px;
            font-weight: 500;
            margin-top: -25px;
            width: 70%;
            text-align: center;

            @include desktop-1366{
                width: 80%;
            }

            @include screen-767{
                width: 90%;
            }
        }
        ul{
            text-align: center;
            padding-top: 40px;
            li{
                color: $white;
                font-size: 24px;
                margin-bottom: 5px;

                @include desktop-1366{
                    font-size: 20px;
                    margin-bottom: 0;
                }
            }
        }
    }
}

// Gallery block
#gallery-section{
    .gallery-title{
        color: #434141;
        font-size: 60px;
        position: relative;
        display: inline-block;
        font-weight: 500;

        &::before{
            bottom: -10px;
            left: 0;
            height: 3px;
            width: 120%;
            position: absolute;
            content: "";
            background: $primary-color;
        }
    }
    .gallery-title{
        
        h2{
            font-size: 6rem;
            font-weight: 500;
            transform: translateX(0%);
        }
    }
    .gallery-layout{
        height: 100vh;
        width: 100%;
        position: relative;
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(3, 1fr);

        @include screen-767{
            grid-template-columns: auto;
            height: 100%;
        }

    }
    .g-bg{
        transition: all 0.5s ease-in-out;
        height: 100%;
        width: 100%;
        position: relative;
        @include screen-767{
            height: 50vh;
        }
    }

    .g-bg:hover {
        transform: scale(1.05);
    }
    .gallery-col-1{
        display: grid;
        grid-gap: 15px;
        grid-template-rows: 1fr 1fr;
        .col-1-top{
            position: relative;
            overflow: hidden;
            
            .g-bg{
                background: url(../assets/images/home-gallery/Hotel-Demo-08.jpg) no-repeat;
                background-position: center;
                background-size: cover;
            }
        }
        .col-1-bottom {
            position: relative;
            overflow: hidden;
            .g-bg{
                background: url(../assets/images/home-gallery/Hotel-Demo-02.jpg) no-repeat;
                background-position: center;
                background-size: cover;
            }
        }
    }
    .gallery-col-2{
        display: grid;
        grid-gap: 15px;
        grid-template-rows:repeat(3, 1fr);

        .col-2-top {
            position: relative;
            overflow: hidden;
            .g-bg{
                background: url(../assets/images/home-gallery/Hotel-Demo-03.jpg) no-repeat;
                background-position: center;
                background-size: cover;
            }
        }
        

        .col-2-center{
            position: relative;
            overflow: hidden;

            .g-bg{
                background: url(../assets/images/home-gallery/Hotel-Demo-04.jpg) no-repeat;
                background-position: center;
                background-size: cover;
            }
        } 

        .col-2-bottom{
            position: relative;
            overflow: hidden;
            .g-bg{
                background: url(../assets/images/home-gallery/Hotel-Demo-05.jpg) no-repeat;
                background-position: center;
                background-size: cover;
            }
        } 
    }
    .gallery-col-3{
        display: grid;
        grid-gap: 15px;
        grid-template-rows: 3fr 2fr;

        @include screen-767{
            grid-template-rows: auto;
        }

        .col-3-top{
            position: relative;
            overflow: hidden; 
        .g-bg{
                background: url(../assets/images/home-gallery/Hotel-Demo-06.jpg) no-repeat;
                background-position: center;
                background-size: cover;
            }
        }
        .col-3-bottom{
            position: relative;
            overflow: hidden; 

        .g-bg{
                background: url(../assets/images/home-gallery/Hotel-Demo-07.jpg) no-repeat;
                background-position: center;
                background-size: cover;
            }
        } 
    }
    .g-bg:before{
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        content: "";
        transition: all 0.5s ease-in-out;
        opacity: 0;

    }

}
#gallery-section .g-bg:hover:before {
    opacity: 1;
}


// *Accommodation page
a.big{
    position: absolute;
    
}