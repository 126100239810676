.inner-header{
    height: 80vh;
    display: grid;
    align-items: center;
    justify-content: center;

    @include screen-767{
        height: 50vh;
    }

    @include phone-580{
        height: 55vh;
    }

    &#request{ 
        background: url(../assets/images/request-header.jpg) no-repeat;
        background-position: center;
        background-size: cover;
    }
    &#contact{ 
        background: url(../assets/images/contact-header.jpg) no-repeat;
        background-position: center bottom;
        background-size: cover;
    }
    &#gallery-header{ 
        background: url(../assets/images/gallery-header.jpg) no-repeat;
        background-position: top;
        background-size: cover;
    }
    &#services{ 
        background: url(../assets/images/services-header.jpg) no-repeat;
        background-position: center top;
        background-size: cover;
    }
    &#accommodation{ 
        background: url(../assets/images/accommodation-header.jpg) no-repeat;
        background-position: 0;
        background-size: cover;
    }

    .inner-hero-title{
        color: $white;
        font-size: 120px;
        font-weight: 500;
        text-shadow: 0 2px 10px rgba(34, 34, 34, 0.5);

        @include screen-767{
            font-size: 40px;
        }
        @include phone-580{
            margin-top: 80px;
        }
    }
}

.welcome-title.req-title{
    color: #434141;
    font-size: 60px;
    position: relative;
    display: inline-block;
    font-weight: 500;

    @include screen-767{
        font-size: 40px;
    }

    &::before{
        bottom: -10px;
        left: 0;
        height: 3px;
        width: 120%;
        position: absolute;
        content: "";
        background: $primary-color;
    }
}

// Facilities
.facilities-block{

    .facilities-title{
        font-size: 60px;
        position: relative;
        display: inline-block;
        font-weight: 500;
        color: #434141;

        @include screen-767{
            font-size: 45px;
        }

        &::before{
            bottom: -10px;
            left: 0;
            height: 3px;
            width: 120%;
            position: absolute;
            content: "";
            background: $primary-color;

            @include phone-580{
                width: 100%;
            }
        }
    }

    .facilitites-wrapper{
        display: grid;
        grid-template-columns: 2fr 2fr 2fr;
        grid-gap: 60px;
        padding: 30px 0;
        height: 650px;

        @include desktop-1366{
            height: 580px;
            grid-gap: 30px;
        }

        @include screen-767{
            min-height: 55px;
            height: 100%;
            grid-template-columns: auto;

            .left-col{
                order: 2;
            }
            .right-col{
                order: 3;
            }
        }
        
        .center-col{
            @include screen-767{
                order: 1;
            }
            .facilities-bg{
                background: url(../assets/images/facilities.jpg) no-repeat;
                background-position: 0;
                background-size: cover;
                width: 100%;
                height: 100%;
                position: relative;

                @include screen-767{
                    min-height: 350px;
                }

                .border-f{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    background: transparent;
                    border: 3px solid $white;
                    height: 90%;
                    width: 90%;

                }
            }
        }
        .f-list{
            background-color: $primary-color;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            padding: 60px;

            @include desktop-1366{
                padding: 40px 30px;
            }
            
            h3{
                color: $white;
                font-size: 2.3rem;
                text-align: center;
                margin-bottom: 30px;
            }
            li, p{
                color: $white;
                font-size: 1.3rem;
                font-weight: 400;
                line-height: 33px;

                @include desktop-1366{
                    font-size: 1.2rem;
                }
            }
            ul{
                @include desktop-1366{
                    padding-left: 25px;
                }
                li{
                    margin-bottom: 3px;
                    position: relative;

                    &::before{
                        position: absolute;
                        left: -25px;
                        top: 12px;
                        width: 8px;
                        height: 8px;
                        background-color: $white;
                        content: "";
                        border-radius: 50px;
                    }
                }
            }
        }
    }

}