/* *ESPA Banner style */
a.espa-banner {
    position: absolute;
    left: 50px;
    bottom: 30px;
    z-index: 12;
  }
  
  @media screen and (max-width: 768px) {
    a.espa-banner {
      right: auto;
      bottom: 30px;
      left: 15px;

      img{
          @include phone-580{
              max-width: 80px;
          }
      }
    }
  }