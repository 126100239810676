// Heading mixings
@mixin heading($font-size) {
    font-size: $font-size;
    color: $heading-color;
  }

  // transition
  @mixin transition {
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
  }


  // default font

  @mixin font-style {
    font-size: 1.25rem;
    line-height: 30px;
    font-weight: normal;
  }

// bottom to top hover effect
@mixin hover-bottom-to-top {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;

    &::before{
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $primary-color-light;
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: top;
      transform-origin: top;
      -webkit-transition-property: transform;
      transition-property: transform;
      -webkit-transition-duration: 0.5s;
      transition-duration: 0.5s;
      -webkit-transition-timing-function: ease;
      transition-timing-function: ease;
    }
    
}

// flex flex center
@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Responsive Media query

@mixin screen-767 {
  @media screen and (max-width: 801px) { @content; }
}

@mixin phone-580 {
  @media screen and (max-width: 767px) { @content; }
}

@mixin desktop-1366 {
  @media screen and  (max-width: 1600px) { @content; }
}

@mixin desktop-1230 {
  @media screen  and (min-width: 1024px) and (max-width: 1229px) { @content; }
}

@mixin desktop-mid {
  @media screen  and (min-width: 1024px) and (max-width: 1399px) { @content; }
}

//iPad Portrait
@mixin iPad-portrait {
    @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px)
    and (orientation : portrait)  { @content; }
}
//iPad landscape
@mixin iPad-landscape {
    @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px)
    and (orientation : landscape)  { @content; }
}

//iPhone 5
@mixin iPhone {
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) 
  and (-webkit-device-pixel-ratio: 2){ @content; }
}
