.contact-block{
    background: $primary-color;
}
#contact-wrap{

    max-width: 1280px;
    padding: 0 15px;
    margin: 0 auto;

    .contact-section-title{
        color: $white;
        font-size: 60px;
        position: relative;
        display: inline-block;
        font-weight: 500;

        &::before{
            bottom: -10px;
            left: 0;
            height: 3px;
            width: 120%;
            position: absolute;
            content: "";
            background: $white;
        }
    }
    //max-width: 1280px;
    margin: 0px auto;

    @include screen-767{
        margin-top: 0;
    }
    
    .contact-form-wrap{
        margin-bottom: 80px;
        box-shadow: 0 2px 10px #000;
    }

    svg{
        height: auto;
        width: 100%;
        fill: #ccc;
        max-width: 30px;

        @include screen-767{
            max-width: 20px;
        }
    }
    h3 {
        font-size: 2.2rem;
        font-weight: 600;
        margin-bottom: 20px;
        color: $primary-color;
        padding-bottom: 15px;
        position: relative;
        @include iPhone{
            font-size: 1.95rem;
        }
    }
    #success_message{
        color: #33a20f!important;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 44px;
        text-align: center;
    }
    .contact-title{
        position: relative;

        &::before{
            width: 70px;
            height: 50px;
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            background: url(../assets/icons/contact/send.svg) no-repeat;
            background-position: 100%;
            background-size: cover;
            opacity: 0.5;

            @include screen-767{
                width: 55px;
            }
        }
    }
    .contact-form-wrap {
        display: grid;
        grid-template-columns: 2fr 1fr;
        justify-content: center;
        background-color: #222;
        .form-wrapper{
            padding: 40px 30px;
            display: grid;
            align-items: center;
            .input-box-col-2{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 25px;
                align-items: center;
                width: 100%;
                margin-bottom: 20px;
                position: relative;
            }
            .input-box-col-1{width: 100%; margin-bottom: 20px; position: relative;}
            input {
                width: 100%;
                padding: 5px 5px 2px 15px;
                border: 1px solid rgba(204, 204, 204, 0.2);
                font-size: 1.25rem;
                color: rgba(255, 255, 255, 0.7);
                font-weight: 500;
                outline: none;
                background: transparent;
                margin-top: 15px;
                font-weight: 400;
            }
            #btn_sent{
                background: #ff5e00;
                width: 100%;
                display: flex;
                align-items: flex-end;
                max-width: 300px;
                text-align: center;
                justify-content: center;
                align-items: center;
                padding: 15px;
                border-radius: 5px;
                font-size: 25px;
                transition: all 0.5s;
                border: 1px solid transparent;
                color: #fff;
                margin: 0 auto;

                &:hover{
                    background: #da3c00;
                }
            }
            label{
                display: inline-block;
                font-size: 1.3rem;
                font-weight: 400;
                font-weight: 500;
                color:$white;

                &.error{
                    font-size: 1rem;
                    left: 0;
                    bottom: -29px;
                    top: auto;
                    color: red;
                }
            }
            textarea#con_message {
                width: 100%;
                height: 120px;
                padding: 15px;
                margin-bottom: 25px;
                border: 1px solid rgba(204, 204, 204, 0.2);
                border-radius: 5px;
                font-size: 1.25rem;
                font-weight: 500;
                color: rgba(255, 255, 255, 0.7);
                margin-top: 15px;
                outline: none;
                background: transparent;
            }

            #success_message{
                @media screen and (max-width: 767px){font-size: 2rem;}
            }
        }
    }
    .information-wrapper {
        .address-box {
            padding-left: 0px;
        }
        background: #353b48;
        color: #fff;
        padding: 50px 60px;
        h2{
            color: #fff;
            text-align: center;
            @include screen-767{
                margin-bottom: 40px;
            }
            @include iPad-portrait{
                margin-bottom: 40px;
            }
        }
        .informtion-box{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            min-height: 40vh;
        }

        .address-box{

            .address{
                margin-bottom: 35px;
                display: flex;
                align-items: center;

                a{
                    @include font-style;
                    color: #fff;
                    margin-left: 10px;
                    font-size: 1.2rem;

                    @include desktop-1366{
                        font-size: 1.2rem;
                    }

                    &:hover{
                        color: $hover;

                        svg{
                            fill:$hover;
                        }
                    }
                }
                svg{
                    margin-right: 15px;

                    @include desktop-1366{
                        margin-right: 8px;
                    }
                }
            }
            
        }
        .contact-social{
            ul{
                text-align: center;
                li{
                    display: inline-block;
                    margin: 0 15px;

                    svg {
                        width: 30px;
                        fill: #fff;
                        transition: all 0.4s;

                        &:hover{
                            fill: $hover;
                        }
                    }
                }
            }
        }
    }
    
    .contact-map-wrap {
        //border: 1px solid #ccc;
        
        iframe {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1599px){
    #contact-wrap {
        max-width: 1080px;

        .contact-form-wrap .form-wrapper label {
            font-size: 1.25rem;
        }

        .information-wrapper {
            padding: 50px 30px;

            .form-wrapper label {
                font-size: 1.25rem !important;
            }
        }
        .contact-form-wrap .form-wrapper .input-box-col-2,  .contact-form-wrap .form-wrapper .input-box-col-1{margin-bottom: 10px;}
    }
   
}

@media screen and (min-width: 1024px) and (max-width: 1279px){
    #contact-wrap {
        max-width: 960px;
        width: 100%;
    }
}
@media screen and  (max-width: 1023px){
    #contact-wrap {
        max-width: 860px;
        width: 100%;
        background: transparent;

        @include screen-767{
            margin-top: 50px;
        }

        @include iPad-portrait{
            margin-top: 65px;
        }
        .information-wrapper .informtion-box{
            align-items: center;

        }
       .contact-form-wrap {
            grid-template-columns: auto;

            .form-wrapper{
                order: 2;
            }
            .information-wrapper{order: 1;}
        }
        .contact-form-wrap, .contact-map-wrap{
            margin: 0 0px 30px;
        }
    }
}
@media screen and  (max-width: 767px){
    #contact-wrap {
        .contact-form-wrap .form-wrapper .input-box-col-2{grid-template-columns: auto;}
        .contact-form-wrap .form-wrapper{padding: 30px 15px;}
    }
    .contact-form-wrap .form-wrapper label {
        font-size: 0.8rem;
        padding-bottom: 5px;
        margin-bottom: 3px;
    }
}
.g-recaptcha {
    width: 100%;
    margin-top: -20px;
    margin-bottom: 15px;
    display: block;
    @media screen and (max-width: 767px){
      margin-top: -10px;
    }
  }
  .g-recaptcha>div {
    margin: 0px auto;
  }
  div#captcha_error {
    /* background: red; */
    display: inline-block;
    width: 100%;
    text-align: center;
    transition: all 0.5s;
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 1.25rem;
    color: red;
  }
  .input-box-col-2>div {
    position: relative;
  }
  #contact-wrap .contact-form-wrap .form-wrapper .input-box-col-1.messageBox {
    position: relative;

    label.error{
        top: auto;
        bottom: 0;
    }
}
.country-title {
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 10px;
}

    .country-title span {
        font-size: 1.8rem;
        font-weight: 600;
        padding: 15px 5px;
        display: block;
        letter-spacing: 0px;
        color: #e96f26;
    }
